// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var menuItem = "FAQ-module--menuItem--2cGHI";
export var title = "FAQ-module--title--282Vs";
export var submenu = "FAQ-module--submenu--Xo69-";
export var link = "FAQ-module--link--3512a";
export var span = "FAQ-module--span--jZgGj";
export var fa = "FAQ-module--fa--LJBIX";
export var open = "FAQ-module--open--3lNQa";
export var instagram = "FAQ-module--instagram--H1hyv";
export var icon = "FAQ-module--icon--3UaFP";
export var faq_content = "FAQ-module--faq_content--2Z4or";