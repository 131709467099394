import BlogLayoutI from "./BlogLayoutI.type";
import BlogInfo from "../BlogInfo/BlogInfo";
import * as styles from "./BlogLayout.module.scss";
import BlogContent from "../BlogContent/BlogContent";

const BlogLayout = ({ blog, setShowModal }: BlogLayoutI) => {
  return (
    <div className={styles.blogContainer}>
      <BlogInfo
        blogTag={blog!.blogTag}
        blogTitle={blog!.blogTitle}
        publishedDate={blog!.publishedDate}
      ></BlogInfo>
      <BlogContent blog={blog} setShowModal={setShowModal}></BlogContent>
    </div>
  );
};

export default BlogLayout;
