import BlogFooterI from "./BlogFooterI.type";
import * as styles from "./BlogFooter.module.scss";
import BlogPostCard from "../BlogPostCard/BlogPostCard";
import BlogPostExcerptNodeI from "../../../types/BlogPostExcerptNodeI.type";

const BlogFooter = ({ relatedArticles }: BlogFooterI) => {
  return (
    <div className={styles.blogFooterContainer}>
      <div className={styles.relatedArticlesContainer}>
        <h2>Related Articles</h2>
        <div className={styles.relatedArticles}>
          {relatedArticles.map((article: BlogPostExcerptNodeI, index) => {
            return (
              <BlogPostCard
                key={`blogPostCard_${index}`}
                blog={article}
              ></BlogPostCard>
            );
          })}
        </div>
      </div>
      {/* Featured Authors section will be added here soon */}
      {/* <BlogEmailCTA></BlogEmailCTA> */}
    </div>
  );
};

export default BlogFooter;
