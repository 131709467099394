import { useState, useEffect } from "react";
import { useCookie } from "../utils/getCookie";
import { graphql } from "gatsby";
import CookiesBanner from "../components/CookiesBanner/CookiesBanner";
import Layout from "../components/Layout/Layout";
import RegisterForm from "../components/RegisterForm/RegisterForm";
import BlogHeroImage from "../components/BlogPost/BlogHeroImage/BlogHeroImage";
import BlogLayout from "../components/BlogPost/BlogLayout/BlogLayout";
import BlogFooter from "../components/BlogPost/BlogFooter/BlogFooter";
import { blogPostMapper } from "../utils/helpers";
import Faqs from "../components/CountryPage/FAQS/Faqs";

const BlogPost = ({ data, pageContext }: any) => {
  const post = pageContext.data;
  let relatedArticles = post?.relatedArticles
    ? blogPostMapper(post?.relatedArticles)
    : data?.allContentfulBlogPost.edges;
  const dataSEO = {
    metaTitle: post?.metaTitle,
    metaDescription: post?.metaDescription?.metaDescription,
    keywords: post?.metaTitle,
    preventIndexing: false,
    alternateHrefLink: true,
  };
  const [showCookiesConsent, setShowCookiesConsent] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const trackCookie = useCookie("gatsby-gdpr-tracking");
    const tagCookie = useCookie("via-gdpr-google-tagmanager");
    if (!trackCookie || !tagCookie) {
      setShowCookiesConsent(true);
    }
  }, []);

  const {
    footer_menu,
    header_menu,
    footer,
    logo,
    cookieBarDescription,
    cookiePolicyLink,
    cookieBarTitle,
  } = pageContext.globalSettings;

  return (
    <div>
      <Layout
        footer_menu={footer_menu}
        header_menu={header_menu}
        footer={footer}
        logo={logo}
        background={true}
        page_background={false}
        dataSEO={dataSEO}
        countryPage={false}
        blog={true}
        url={pageContext.url}
        setModal={setShowModal}
      >
        <BlogHeroImage image={post!.heroImage}></BlogHeroImage>
        <BlogLayout blog={post} setShowModal={setShowModal}></BlogLayout>
        <BlogFooter relatedArticles={relatedArticles}></BlogFooter>
        {post.faq && <Faqs faqs={post.faq} grey={false}></Faqs>}
        {showCookiesConsent && (
          <CookiesBanner
            dismiss={() => setShowCookiesConsent(false)}
            cookieBarDescription={cookieBarDescription}
            cookiePolicyLink={cookiePolicyLink}
            cookieBarTitle={cookieBarTitle}
          />
        )}
        {showModal && (
          <RegisterForm modal={showModal} setModal={setShowModal} />
        )}
      </Layout>
    </div>
  );
};

export const query = graphql`
  query myRelatedArticles($blogTag: String, $blogTitle: String) {
    allContentfulBlogPost(
      limit: 3
      filter: {
        blogTag: { tagName: { eq: $blogTag } }
        blogTitle: { ne: $blogTitle }
      }
    ) {
      edges {
        node {
          slug
          blogTitle
          blogTag {
            slug
            tagName
          }
          excerpt
          heroImage {
            gatsbyImageData(formats: WEBP, height: 196, placeholder: BLURRED)
          }
          publishedDate(formatString: "MMM Do, YYYY")
        }
      }
    }
  }
`;

export default BlogPost;
