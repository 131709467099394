import BlogAuthorInfoI from "./BlogAuthorInfoI.type";
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { excerpt } from "../../../utils/helpers";
import * as styles from "./BlogAuthorInfo.module.scss";

const BlogAuthorInfo = ({ author }: BlogAuthorInfoI) => {
  return (
    <div className={styles.authorInfoWrapper}>
      <div className={styles.authorPhoto}>
        <GatsbyImage
          image={author.photo.gatsbyImageData}
          alt={author.photo.title}
        ></GatsbyImage>
      </div>
      <div className={styles.authorInfo}>
        <div className={styles.authorName}>{author.fullName}</div>
        <div className={styles.authorBio}>{author.bio.bio}</div>
      </div>
    </div>
  );
};

export default BlogAuthorInfo;
