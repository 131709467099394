import React from "react";
import * as styles from "./SectionCTA.module.scss";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import Button from "../../Button/Button";
import { cn } from "../../../utils";
import SectionCTAI from "./SectionCTA.type";

const SectionCTA = ({
  title,
  button,
  smallTitle,
  bg_color,
  borderRadius,
  light = false,
  btnSize = "large",
  setModal,
}: SectionCTAI) => {
  return (
    <div
      className={cn([
        styles.element,
        borderRadius ? styles.borderRadius : "",
        light ? styles.lightBg : "",
        bg_color ? styles[bg_color] : styles["dark"],
      ])}
    >
      {smallTitle ? <h4 className={styles.smallTitle}>{smallTitle}</h4> : ""}
      <HeaderTitle
        type={"section"}
        title={title.title}
        titleColor={title.title_color}
        secondTitle={title.second_title}
        secondTitleColor={title.second_title_color}
        text={title.description}
        secondLine={title.second_line}
        classNameTitle={styles.title}
        classNameText={styles.description}
      />
      {button && (
        <Button
          className={styles.button}
          text={button.button_text}
          color={button.bg_color ? button.bg_color : "white"}
          size={btnSize}
          contactButton={true}
        />
      )}
    </div>
  );
};

export default SectionCTA;
