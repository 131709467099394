// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var blogInfoWrapper = "BlogInfo-module--blogInfoWrapper--1neqP";
export var blogTag = "BlogInfo-module--blogTag--2aE1y";
export var blogTitle = "BlogInfo-module--blogTitle--297yO";
export var publishedDate = "BlogInfo-module--publishedDate--2jbmc";