import BlogHeroImageI from "./BlogHeroImageI.type";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./BlogHeroImage.module.scss";
import React from "react";

const BlogHeroImage = ({ image }: BlogHeroImageI) => {
  return (
    <>
      {image && (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image!.title}
          className={styles.blogHeroImage}
        ></GatsbyImage>
      )}
    </>
  );
};

export default BlogHeroImage;
