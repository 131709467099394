import { cn } from "../../../utils";
import FAQ from "./FAQ/FAQ";
import * as styles from "./Faqs.module.scss";

const Faqs = ({ faqs, grey }) => {
  return (
    <div>
      <div className={styles.main_section}>
        <div className={cn([styles.faq_section, grey ? styles.grey : ""])}>
          <h2>
            Frequently asked <span>questions</span>
          </h2>
          {faqs?.map(item => {
            return <FAQ title={item.question} desc={item.answers.answers} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
