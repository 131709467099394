// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var main_section = "Faqs-module--main_section--2oAfp";
export var faq_section = "Faqs-module--faq_section--FUmlv";
export var grey = "Faqs-module--grey--BuAg7";