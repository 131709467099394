import React from "react";
import BlogInfoI from "./BlogInfoI.type";
import * as styles from "./BlogInfo.module.scss";

const BlogInfo = ({ publishedDate, blogTitle, blogTag }: BlogInfoI) => {
  return (
    <div className={styles.blogInfoWrapper}>
      <div className={styles.blogTag}>{blogTag.tagName}</div>
      <h1 className={styles.blogTitle}>{blogTitle}</h1>
      <div className={styles.publishedDate}>{publishedDate}</div>
    </div>
  );
};

export default BlogInfo;
