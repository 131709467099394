// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var authorPhoto = "BlogAuthorInfo-module--authorPhoto--3hlB3";
export var authorInfoWrapper = "BlogAuthorInfo-module--authorInfoWrapper--1akD4";
export var authorInfo = "BlogAuthorInfo-module--authorInfo--24huO";
export var authorName = "BlogAuthorInfo-module--authorName--3b2Kl";
export var authorBio = "BlogAuthorInfo-module--authorBio--2rTmm";