// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var cardContainer = "BlogPostCard-module--cardContainer--1O4MC";
export var cardImgContainer = "BlogPostCard-module--cardImgContainer--1eY7C";
export var cardGatsbyImg = "BlogPostCard-module--cardGatsbyImg--2dL8u";
export var cardBlogTag = "BlogPostCard-module--cardBlogTag--37vOb";
export var cardBlogTitle = "BlogPostCard-module--cardBlogTitle--Zz3Ld";
export var cardBlogExcerpt = "BlogPostCard-module--cardBlogExcerpt--4Ko8B";
export var cardPublishedDate = "BlogPostCard-module--cardPublishedDate--1cWwZ";
export var cardLink = "BlogPostCard-module--cardLink--2rpwu";