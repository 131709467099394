import { useEffect, useRef, useState } from "react";
import { cn } from "../../../../utils";
import * as styles from "./FAQ.module.scss";

const FAQ = ({ title, desc }) => {
  const ref = useRef<HTMLLIElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const checkIfClickedOutside = (e: any) => {
    if (isOpen && ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  });
  return (
    <li
      ref={ref}
      className={cn([styles.menuItem, isOpen ? styles.open : ""])}
      onClick={() => setIsOpen(oldState => !oldState)}
    >
      <span className={cn([styles.title, styles.span, styles["icon"]])}>
        <h3>{title}</h3>
      </span>
      <span className={styles.fa} />

      <div className={cn([styles.submenu, isOpen ? styles.open : ""])}>
        <div className={cn([styles.faq_content, styles.link])}>
          <p>{desc}</p>
        </div>
      </div>
    </li>
  );
};

export default FAQ;
