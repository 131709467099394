// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var element = "SectionCTA-module--element--1aYEA";
export var dark = "SectionCTA-module--dark--2s8pB";
export var white = "SectionCTA-module--white--2XURN";
export var grey = "SectionCTA-module--grey--1wh0T";
export var blue = "SectionCTA-module--blue--3wAgC";
export var peach = "SectionCTA-module--peach--2ErME";
export var smallTitle = "SectionCTA-module--smallTitle--3TPnq";
export var description = "SectionCTA-module--description--3JvKg";
export var button = "SectionCTA-module--button--U2lED";
export var borderRadius = "SectionCTA-module--borderRadius--1nbKv";
export var lightBg = "SectionCTA-module--lightBg--3Vahu";
export var title = "SectionCTA-module--title--3yLAV";