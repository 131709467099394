// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var blogContentWrapper = "BlogContent-module--blogContentWrapper--3PNlj";
export var videoWrapper = "BlogContent-module--videoWrapper--1skLz";
export var gatsImage = "BlogContent-module--gatsImage--27az8";
export var section = "BlogContent-module--section--3R6Dh";
export var darkh2 = "BlogContent-module--darkh2--2TLge";
export var darkh3 = "BlogContent-module--darkh3--3VTuV";
export var peachh2 = "BlogContent-module--peachh2--1m0C6";
export var peachh3 = "BlogContent-module--peachh3--3kECb";
export var whiteh2 = "BlogContent-module--whiteh2--2rt6R";
export var whiteh3 = "BlogContent-module--whiteh3--29MeC";
export var greenh2 = "BlogContent-module--greenh2--28e_k";
export var greenh3 = "BlogContent-module--greenh3--TWdZH";
export var blueh2 = "BlogContent-module--blueh2--31FSs";
export var blueh3 = "BlogContent-module--blueh3--30NBd";
export var goldh2 = "BlogContent-module--goldh2--1E4-l";
export var goldh3 = "BlogContent-module--goldh3--1fzE7";
export var sectionCTA = "BlogContent-module--sectionCTA--2kAlD";
export var authorsContainer = "BlogContent-module--authorsContainer--2jRm5";