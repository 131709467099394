import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import SectionCTA from "../../Sections/SectionCTA/SectionCTA";
import BlogContentI from "./BlogContentI.type";
import * as styles from "./BlogContent.module.scss";
import BlogAuthorInfo from "../BlogAuthorInfo/BlogAuthorInfo";
import { GatsbyImage } from "gatsby-plugin-image";
import { cn } from "../../../utils";

const BlogContent = ({ blog, setShowModal }: BlogContentI) => {
  const Title = {
    type: "section",
    title: "Need help building your global team?",
    title_color: "white",
    second_line: false,
    second_title_color: "",
  };
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const { gatsbyImageData, title, file } = node.data.target;
        if (gatsbyImageData) {
          // Handle images
          return (
            <GatsbyImage
              image={gatsbyImageData}
              alt={title}
              className={styles.gatsImage}
            />
          );
        } else if (file) {
          const videoUrl = file.url;
          return (
            <video className={styles.videoWrapper} controls>
              <source
                src={"https:" + videoUrl}
                type={node.data.target.file.contentType}
              />
              Your browser does not support playback of videos using the video
              tag.
            </video>
          );
        }
      },
    },
  };
  return (
    <div className={styles.blogContentWrapper}>
      {blog!.sectionOneContent && (
        <div
          className={cn([
            styles.section,
            blog!.sectionOneH2Color
              ? styles[`${blog!.sectionOneH2Color}h2`]
              : "",
            blog!.sectionOneH3Color
              ? styles[`${blog!.sectionOneH3Color}h3`]
              : "",
          ])}
        >
          {renderRichText(blog!.sectionOneContent, options)}
        </div>
      )}
      {blog!.sectionTwoContent && (
        <div
          className={cn([
            styles.section,
            blog!.sectionTwoH2Color
              ? styles[`${blog!.sectionTwoH2Color}h2`]
              : "",
            blog!.sectionTwoH3Color
              ? styles[`${blog!.sectionTwoH3Color}h3`]
              : "",
          ])}
        >
          {renderRichText(blog!.sectionTwoContent, options)}
        </div>
      )}
      <div className={styles.sectionCTA}>
        <SectionCTA
          title={Title}
          button={{
            button_text: "Talk to us",
          }}
          smallTitle={""}
          borderRadius={"13px"}
          light={true}
          btnSize={"medium"}
          setModal={setShowModal}
        ></SectionCTA>
      </div>
      {blog!.sectionThreeContent && (
        <div
          className={cn([
            styles.section,
            blog!.sectionThreeH2Color
              ? styles[`${blog!.sectionThreeH2Color}h2`]
              : "",
            blog!.sectionThreeH3Color
              ? styles[`${blog!.sectionThreeH3Color}h3`]
              : "",
          ])}
        >
          {renderRichText(blog!.sectionThreeContent, options)}
        </div>
      )}
      {blog.authors ? (
        <div className={styles.authorsContainer}>
          {blog.authors.map((author, index) => {
            return (
              <BlogAuthorInfo key={index} author={author}></BlogAuthorInfo>
            );
          })}
        </div>
      ) : (
        <BlogAuthorInfo author={blog.author}></BlogAuthorInfo>
      )}
    </div>
  );
};

export default BlogContent;
