// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var blogFooterContainer = "BlogFooter-module--blogFooterContainer--1BrmK";
export var relatedArticles = "BlogFooter-module--relatedArticles--nIww2";
export var relatedArticlesContainer = "BlogFooter-module--relatedArticlesContainer--2ukVw";