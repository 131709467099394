import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import BlogPostCardI from "./BlogPostCardI.type";
import * as styles from "./BlogPostCard.module.scss";

const BlogPostCard = ({ blog: { node } }: BlogPostCardI) => {
  return (
    <div className={styles.cardContainer}>
      <a href={`/blog/${node.slug}`} className={styles.cardLink}>
        <div className={styles.cardImgContainer}>
          {node!.heroImage && (
            <GatsbyImage
              image={node!.heroImage.gatsbyImageData}
              alt=""
              className={styles.cardGatsbyImg}
            ></GatsbyImage>
          )}
        </div>
        <div className={styles.cardBlogTag}>{node!.blogTag?.tagName}</div>
        <div className={styles.cardBlogTitle}>{node!.blogTitle}</div>
        <div className={styles.cardBlogExcerpt}>{node!.excerpt}</div>
        <div className={styles.cardPublishedDate}>{node!.publishedDate}</div>
      </a>
    </div>
  );
};

export default BlogPostCard;
